import React from "react";
import { graphql, Link } from "gatsby";
import Helmet from 'react-helmet';
import { animated, Trail } from 'react-spring';
import * as moment from 'moment';
import * as styles from './Entry.module.scss';

const EntryPageTemplate = ({ data: { mdx: { frontmatter } } }) => {
  console.log(frontmatter);
  const images = frontmatter.images.map((imageUrl, index) => (
    <div
      key={imageUrl}
      className={styles.ImageContainer}
    >
      <img
        className={styles.EntryImage}
        src={imageUrl}
        alt={`${moment(frontmatter.date, 'YYYY-MM-DD').format('MMM D, YYYY')} - ${frontmatter.title}: ${index}`}
      />
      <div className={styles.ImageOverlay} />
    </div>
  ));

  const components = [
    // Date
    <div key="Date" className={styles.Date}>
      {moment(frontmatter.date, 'YYYY-MM-DD').format('MMMM D, YYYY')}
    </div>,
    // Title
    <div key="Title" className={styles.Title}>{frontmatter.title}</div>,
  ].concat(images).concat([
    // Back
    <div className={styles.BackContainer}>
      <Link
        className={styles.Back}
        to="/"
        key="Back"
      >
        &#8592; BACK
      </Link>
    </div>,
  ]);

  return (
    <div className={styles.Page}>
        <Helmet>
            {/* General tags */}
            <title>{frontmatter.title}</title>
            <meta name="description" content={frontmatter.tagDescription} />
            <meta name="image" content={frontmatter.tagImage} />

            {/* OpenGraph tags */}
            <meta property="og:url" content={frontmatter.tagUrl} />
            <meta property="og:title" content={frontmatter.title} />
            <meta property="og:description" content={frontmatter.tagDescription} />
            <meta property="og:image" content={frontmatter.tagImage} />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="@_christianle_" />
            <meta name="twitter:title" content={frontmatter.title} />
            <meta name="twitter:description" content={frontmatter.tagDescription} />
            <meta name="twitter:image" content={frontmatter.tagImage} />
        </Helmet>
        <div className={styles.Container}>
            <Trail
                items={components}
                keys={['Date', 'Title'].concat(frontmatter.images).concat(['Back'])}
                native={true}
                from={{ opacity: 0, transform: 'translateY(60px)' }}
                to={{ opacity: 1, transform: 'translateY(0px)' }}
            >
                {(item) => (props) => <animated.div style={props} className="item" children={item} />}
            </Trail>
        </div>
    </div>
  );
}

export default EntryPageTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        title
        images
        tagImage
        tagDescription
        tagUrl
      }
    }
  }
`;
